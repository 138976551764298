import Splide from '@splidejs/splide';

class Carousel {
    constructor() {
        this.carousel = null;
        this.transitionSpeed = 3000;
        this.activeSlideClass = 'animate';

        this.init();
    }

    init() {
        if (!this.carousel) {
            this.carousel = new Splide('.splide', {
                arrows: false,
                autoplay: true,
                drag: false,
                interval: 5000,
                pauseOnHover: false,
                rewind: true,
                rewindSpeed: this.transitionSpeed,
                speed: this.transitionSpeed,
                type: 'fade',
            }).mount();

            this.carousel.on('active', (slide) => {
                window.setTimeout(() => slide.slide.classList.add(this.activeSlideClass), this.transitionSpeed / 2);
            });

            this.carousel.on('inactive', (slide) => {
                window.setTimeout(() => slide.slide.classList.remove(this.activeSlideClass), this.transitionSpeed);
            });
        }
    }
}

export default function () {
    const carousel = document.querySelector('.splide');

    if (carousel) {
        new Carousel(carousel);
    }
}
