import "../css/style.css";
import nav from "./nav";
import search from "./search";
import carousel from "./carousel";
import localeSwitcher from "./locale-switcher";

const loadEvent = import.meta.env.VITE_STATIC ?? false ? "static-load" : "load";
window.addEventListener(loadEvent, () => {
    nav();
    search();
    carousel();
    localeSwitcher();
})

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}
