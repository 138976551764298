class Search {
    constructor (hasHeaderSearch) {
        this.searchForm = document.querySelectorAll('[data-js-hook="search-form"]');

        if (hasHeaderSearch) {
            this.openButton = document.querySelector('[data-js-hook="open-search"]');
            this.closeButton = document.querySelector('[data-js-hook="close-search"]');
            this.searchWrapper = document.querySelector('[data-js-hook="search-wrapper"]');
            this.headerSearchInput = this.searchWrapper.querySelector('input');
            this.navWrapper = document.querySelector('[data-js-hook="nav-wrapper"]');
            this.navOptions = this.navWrapper.querySelector('[data-js-hook="nav-options"]');
            this.openButton.addEventListener('click', this.openSearch.bind(this));
            this.closeButton.addEventListener('click', this.closeSearch.bind(this));
            this.searchClass = 'search-is-open';
            this.isOpen = false;
        }

        if (this.searchForm) {
            this.searchForm.forEach((form) => {
                form.addEventListener('submit', (e) => {
                    e.preventDefault();
                    window.location.href = `${form.getAttribute('data-results')}?s=${e.target[0].value}`;
                })
            });
        }
    }

    toggleClass () {
        [this.searchWrapper, this.navWrapper].forEach((el) => {
            el.classList.toggle(this.searchClass);
        });
    }

    toggleAriaHidden (el, isHidden) {
        el.setAttribute('aria-hidden', isHidden);
    }

    openSearch () {
        this.navOptions.classList.add(this.searchClass);

        this.toggleClass();
        window.setTimeout(() => {
            this.headerSearchInput.focus();
        }, 1000);
        this.toggleAriaHidden(this.searchWrapper, false);
        this.toggleAriaHidden(this.navWrapper, true);
        this.isOpen = true;
    }

    closeSearch () {
        this.toggleClass();
        this.searchWrapper.setAttribute('aria-hidden', true);
        this.navWrapper.setAttribute('aria-hidden', false);
        this.isOpen = false;

        window.setTimeout(() => {this.navOptions.classList.remove(this.searchClass)}, 1100);
    }
}

export default function () {
    new Search(document.querySelector('[data-js-hook="search-wrapper"]'));
}
