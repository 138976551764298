class Nav {
    constructor(navWrapper) {
        this.isOpen = false;
        this.trigger = navWrapper.querySelector('[data-js-hook="toggle-nav"]');
        this.sectionHeadings = navWrapper.querySelectorAll('[data-js-hook="section-heading"]');
        this.navOptions = navWrapper.querySelector('[data-js-hook="nav-options"]');
        this.mousedown = false;

        this.bindEvents();
    }

    bindEvents() {
        this.trigger.addEventListener('click', this.toggleMainNav.bind(this));
        this.trigger.addEventListener('blur', () => {
            if (this.isOpen) {
                this.navOptions.querySelector('[tabindex]').focus();
            }
        });

        document.body.addEventListener('keydown', (e) => {
            if (this.isOpen) {
                if (e.key === "Escape") {
                    this.closeMainNav();
                }
            }
        })

        this.sectionHeadings.forEach((el) => {
            const parent = el.parentElement;

            el.addEventListener('mousedown', () => {
                this.mousedown = true;
            });

            el.addEventListener('mouseup', () => {
                this.mousedown = false;
            });

            el.addEventListener('focus', (e) => {
                if (this.mousedown) {
                    el.blur();
                } else {
                    parent.classList.add('focused');
                }
            });

            el.addEventListener('blur', () => {
                parent.classList.remove('focused');
            });
        });
    }

    openMainNav() {
        this.navOptions.classList.add('is-open');
        this.isOpen = true;
    }

    closeMainNav() {
        this.navOptions.classList.remove('is-open');
        this.isOpen = false;
    }

    toggleMainNav() {
        if (this.isOpen) {
            this.closeMainNav();
        } else {
            this.openMainNav();
        }

        this.navOptions.setAttribute('aria-hidden', !this.isOpen);
    }
}

export default function () {
    const navWrapper = document.querySelector('[data-js-hook="nav-wrapper"]');

    if (navWrapper) {
        new Nav(navWrapper);
    }
}
